import React, { useRef, useEffect, useState } from 'react';
import Chips from './../../chips/Chips';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import UpArrow from "../../../assets/icons/up-arrow.svg";
import CurationMansonry from './../../curations/CurationMansonry';
import { useSelector } from 'react-redux';
import { domainUrl } from './../../../utils/globals';

const MasonryItem = ({ item }) => {
  const myData = useSelector((state) => state.myData);
  const isOwner = myData._id === item.user._id;
  // console.log(isOwner);

  if (item.type === 'curation') {
    return (
      <div className="curation-item my-masonry-grid_item">
        <CurationMansonry curation={item} owner={isOwner} gallery={true} />
      </div>
    );
  } else if (item.type === 'chip') {
    return (
      <div className="chip-item my-masonry-grid_item">
      <Chips item={item}/>
      </div>
    );
  }
  return null;
};

  

const SubscriptionsList = ({profile,items,onHide}) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 4,
    900:3,
    700: 2,
    600: 2,
    460:2,
    300:1
  };
  const containerRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);

//   const handleScroll = () => {
//     const container = containerRef.current;
//     const isBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
//     setIsAtBottom(isBottom);
//   };

useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const isBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
        setIsAtBottom(isBottom);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleHideClick = () => {
    onHide();
    if (containerRef.current && items.length>0) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        window.scrollBy(0, -160); 
      }, 50); 
    }
  };

  const handleProfilePage=(username)=>{
    const url = `https://${username}.${domainUrl}`;
    window.open(url, '_blank');
  }
 
      
  return (
    <div className="-mt-2 flex flex-col" ref={containerRef} >
        <div className="flex flex-row items-center mt-6">
            <p className="text-white text-sm font-inter font-normal">Recent from {profile.name}</p>
            <p className="text-primary ml-3 text-xs font-extralight font-inter cursor-pointer" onClick={()=>handleProfilePage(profile.username)}>visit profile -></p>
        </div>
       {items.length>0? <div className="w-full mt-3">
        <ResponsiveMasonry
        columnsCountBreakPoints={breakpointColumnsObj}
            >
            <Masonry
              className="my-masonry-grid"
              gutter="18px"
              columnClassName="my-masonry-grid_column"
            >
              {items.map(item => (
                <MasonryItem key={item._id} item={item}/>
              ))
          }
            </Masonry>
            </ResponsiveMasonry>

      
    </div>:<div className="flex items-center justify-center mx-auto mt-10 mb-12">
            <div className="container rounded-md bg-dark pl-4 pr-4 pt-3 pb-3 flex flex-col min-w-fit  " >
              <h3 className=" text-textColor text-sm">(. ❛ ᴗ ❛.) Seems like this profile is empty</h3>
              <div className="mt-2 rounded-md bg-chipBackground pl-6 pt-4 pb-4 cursor-pointer" >
                <h3 className=" text-textColor">Explore more -></h3>
              </div>
            </div>
          </div>}
    <div className="relative mt-5">
        <div className="horizontal-line bg-borderColor h-px mr-5 " />
            <div className="absolute -top-3 w-full flex flex-col items-center cursor-pointer ">
            <img src={UpArrow} alt="up-arrow"
                onClick={handleHideClick}
                className=" text-borderColor  -mt-0.5 font-extralight "
            >
            </img>
            <p className="text-lightText text-xs cursor-pointer -mt-0.5 ml-1">Hide</p>
            </div>
      </div>
    </div>
  );
};









export default SubscriptionsList
