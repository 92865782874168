import {React,useEffect} from 'react';
import Top5 from '../../../assets/images/Top5.svg';
import { useDispatch, useSelector } from 'react-redux';
import ProfileIcon from "../../../assets/icons/profile.svg";
import { useNavigate } from 'react-router-dom';
import { fetchTrendingProfile } from './../../../redux/slices/homeCardSlice';
import BannerProfileSkeleton from './../../skeleton/banners/bannerProfileSkeleton';


const MiddleComponent = () => {

  const dispatch = useDispatch();

  const {profiles,profileStatus} = useSelector((state)=>state.homeCard);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTrendingProfile())
  }, [dispatch]);


  const handleProfileOpen=(username)=>{
    navigate(`/profile/${username}`)
  }

  
  return (
    <div className="bg-dark px-3 xs:px-5 pt-2 pb-4 rounded-lg flex flex-row h-64">
      <div className="sm:mr-12 xs:mr-4 xxl:mr-16 mr-2">
        <img src={Top5} alt="Logo" className="h-12 mt-6" />
        <h2 className="text-white xs:text-xl text-lg sm:text-2xl font-medium font-familjen-grotesk mt-10 xs:mt-14 w-24 xs:w-32">Top 5 Profiles</h2>
        <p className="text-textColor text-xs font-light font-familjen-grotesk mt-1 sm:mt-2 w-24 xs:w-36">People engaged with their content the most</p>
      </div>
      <div className="w-44 xs:w-48 sm:w-56 ">
      { profileStatus==="loading"?<BannerProfileSkeleton/>:profiles.map((profile, index) => (
        <div key={index} className={`flex items-center justify-between py-1.5 ${index===profiles.length-1?"":'border-b border-borderColor cursor-pointer' }`} 
        onClick={()=>handleProfileOpen(profile.username)}>
          <div className="flex items-center w-[90%]">
            <img src={profile.logo?profile.logo:ProfileIcon} alt={profile.name} className="h-9 w-9 rounded-xl object-cover" />
            <span className="sm:ml-4 ml-2 text-white font-normal font-inter text-xs xs:text-sm overflow-hidden overflow-ellipsis truncate"
            >{profile.name}</span>
          </div>
          <div className="text-primary font-normal pl-1 text-xs xs:text-sm">
            ->
          </div>
        </div>
      ))}
      </div>
      
  </div>
);
};

export default MiddleComponent;
