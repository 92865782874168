import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store/store';

function clearOldCaches() {
  if ('caches' in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        if (cacheName.includes('flutter-app-cache') ||cacheName.includes('flutter-app-manifest') ) {
          caches.delete(cacheName);
        }
      });
    }).catch((error) => {
      console.error('Error clearing caches:', error);
    });
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration.active && registration.active.scriptURL.includes('flutter_service_worker.js')) {
          registration.unregister().then(() => {
            console.log('Old Flutter service worker unregistered');
          });
        }
      });
    }).catch((error) => {
      console.error('Error unregistering service worker:', error);
    });
  }
}

clearOldCaches();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={store}>
        <App />
      </Provider>
);
