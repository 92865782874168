import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Welcome from '../../../assets/images/welcome.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const LeftComponent = ({ onboardClick, onStartedClick }) => {
  const cards = [
    { title: "More Than Just a Link-in-bio", text: "Don’t just put blind links together. Add versatile formats to your links and group them into meaningful curation for more context and direct engagement with your audience. Don’t let your site get lost in bios—stay relevant and increase your reach through the discovery feed.", image: "https://chips-social.s3.ap-south-1.amazonaws.com/cards/card_1.png" },
    { title: "Create Your Digital Space", text: "Build your website effortlessly without relying on developers or designers. Creating your website is now as simple as texting with Chips. Set up a custom web address, seamlessly organize your information, and actively engage with your community. No code required, just pure bliss!", image: "https://chips-social.s3.ap-south-1.amazonaws.com/cards/card_2.png" },
    { title: "Manage and Share Content", text: "Explore curations and profiles that match your vibes. Subscribe to profiles to stay updated whenever new content is added. Publish your work on the explore page to reach a wider audience, and share your Chips domain with friends to create a shared space for quality content.", image: "https://chips-social.s3.ap-south-1.amazonaws.com/cards/card_3.png" },
    { title: "Discover and Connect", text: "Explore curations and profiles that match your vibes. Subscribe to profiles to stay updated whenever new content is added. Publish your work on the explore page to reach a wider audience, and share your Chips domain with friends to create a shared space for quality content.", image: "https://chips-social.s3.ap-south-1.amazonaws.com/cards/card_4.jpg" },
  ];

  return (
    <div className="bg-dark px-3 md:px-5 pt-4 pb-4 sm:pb-5 rounded-lg flex flex-col sm:flex-row h-full sm:h-72">
      <div className="sm:mr-2 md:mr-8 lg:mr-12  flex sm:flex-col flex-row items-center sm:items-start">
          <img src={Welcome} alt="Logo" className="sm:h-12 xs:h-10 h-9 sm:mt-4" />
          <h1 className="text-white text-lg xs:text-xl sm:text-2xl font-medium font-familjen-grotesk sm:mt-20 sm:w-36 sm:ml-1 ml-3">
            Welcome to Chips
          </h1>
          <button
            onClick={onStartedClick}
            className="get-started sm:mt-3 sm:px-5 xs:px-3 px-2 sm:py-2.5 py-2 bg-primary rounded-lg text-buttonText font-normal font-inter text-xs xs:text-sm focus:outline-none mr-2 xs:mr-4 sm:mr-0"
          >
            Get Started
          </button>
      </div>
      <Swiper
        spaceBetween={0}
        pagination={{ clickable: true,}}
        loop={true}
        // navigation={{
        //   nextEl: '.custom-swiper-button-next',
        //   prevEl: '.custom-swiper-button-prev',
        // }}
        modules={[Navigation, Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 1, 
          },
          800: {
            slidesPerView: 1.1, 
          },
          900: {
            slidesPerView: 1.3,
          },
          1000: {
            slidesPerView: 1.5,
          },
          1100: {
            slidesPerView: 1.7, 
          },
          1200: {
            slidesPerView:1.9,
          },
          1300: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 2.2,
          },
          1500: {
            slidesPerView: 2.4, 
          },
          1600:{
            slidesPerView: 2.5,
          },
          1700:{
            slidesPerView: 2.5,
          }
        }}
        className="w-full h-max xs:h-64"
      >
        {cards.map((card, index) => (
          <SwiperSlide key={index}>
            <div className="bg-cardBackground rounded-lg overflow-hidden mt-3 sm:mt-0 flex-shrink-0 flex flex-row items-end py-4 px-2 sm:py-4 sm:px-4 w-[97%] h-max xs:h-[244px] sm:h-full cursor-grabbing">
              <div className="flex flex-col sm:w-1/2 md:w-3/5 justify-end  pr-2 md:pr-4">
                <p className="font-familjen-grotesk font-medium text-white text-base xs:text-md md:text-lg lg:text-xl">{card.title}</p>
                <p className="font-familjen-grotesk font-normal text-textColor text-[10px] xs:text-xs mt-2">{card.text}</p>
              </div>
              <img src={card.image} alt="carousel" className="w-3/5 xs:w-1/2 md:w-auto h-full object-contain" />
            </div>
          </SwiperSlide>
        ))}
          {/* <div className="md:hidden flex custom-swiper-button-prev absolute left-1/3 px-2.5 -bottom-5 transform -translate-y-1/2 bg-black bg-opacity-30 text-white py-2 rounded-full cursor-pointer z-50">
        <FontAwesomeIcon icon={faArrowLeft} size="lg" />
      </div>

      <div className="md:hidden flex custom-swiper-button-next absolute right-1/3 px-2.5 -bottom-5 transform -translate-y-1/2 bg-black bg-opacity-30 text-white py-2 rounded-full cursor-pointer z-50">
        <FontAwesomeIcon icon={faArrowRight} size="lg" />
      </div> */}
        {/* <div className="swiper-button-prev custom-swiper-button-prev"></div>
        <div className="swiper-button-next custom-swiper-button-next"></div> */}
      </Swiper>
     
      
      
    </div>
  );
};

export default LeftComponent;
