import {React,useEffect} from 'react';
import Chips from './../../chips/Chips';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import CurationMansonry from './../../curations/CurationMansonry';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProfileItems ,fetchGalleryItems,clearItems} from '../../../redux/slices/profileItemsSlice';
import ProfileItemsSkeleton from './../../skeleton/profileItemsSkeleton';
import EmptyItemsCard from './EmptyItemsCard';
import ProfileChips from './../../chips/ProfileChips';


const MasonryItem = ({ item, owner,gallery }) => {
    if (item.type === 'curation') {
      return (
        <div className="curation-item my-masonry-grid_item">
          <CurationMansonry curation={item} owner={owner} gallery={gallery} />
        </div>
      );
    } else if (item.type === 'chip') {
      return (
        <div className="chip-item my-masonry-grid_item">
         {owner ? <ProfileChips item={item} />:<Chips item={item}/> } 
        </div>
      );
    }
    return null;
  };

const ProfileView = ({gallery ,owner}) => {
  const profileData = useSelector((state) => state.profileData);
  const galleryData = useSelector((state) => state.galleryData);

  const dispatch = useDispatch();

  const { items, status, error } = useSelector((state) => state.profileItems);

  useEffect(() => {
    dispatch(clearItems());
    if(!gallery && profileData._id){
     dispatch(fetchProfileItems(profileData._id));
     }
     else if(gallery && galleryData.username){
      dispatch(fetchGalleryItems(galleryData.username));
     }
  }, [dispatch, profileData._id,galleryData.username,gallery]);
    const breakpointColumnsObj = {
        default: 4,
        1500:4,
        1150:3,
        1100: 4,
        900:3,
        700: 2,
        600: 2,
        460:2,
        300:1
      };

      if (status === 'loading') {
        return <ProfileItemsSkeleton/>
      }
    
      if (status === 'failed') {
        return <div>Error: {error}</div>;
      }

      if (items.length === 0 && owner) {
        return (
         <EmptyItemsCard/>
        );
      }
    
    
  return (
    <div className=" w-full">
        <ResponsiveMasonry
        columnsCountBreakPoints={breakpointColumnsObj}
    >
    <Masonry
      className="my-masonry-grid"
      gutter="18px"
      columnClassName="my-masonry-grid_column"
    >
      {items.map(item => (
        <MasonryItem key={item._id} item={item} owner={owner} gallery={gallery} />
      ))}
    </Masonry>
    </ResponsiveMasonry>

      
    </div>
  )
}

export default ProfileView
