import React from 'react'
// import Logo from "../../../assets/icons/chips.png";


const MetaCard = ({ title, description, imageUrl, link }) => {
  const isValidUrl = imageUrl && imageUrl.startsWith('https://');
  const image = "https://www.netsuite.com/portal/assets/img/business-articles/data-warehouse/social-metadata.jpg";
    return (
      <div className="max-w-md bg-dark rounded-xl shadow-md overflow-hidden mr-4 ">
        <a href={link} className="block">
          <div className="px-1.5 py-1.5">
            <div className="text-sm font-normal font-inter text-metaLink">{title}</div>
            <p className="mt-1 text-textColor text-xs font-normal font-inter">{description}</p>
          </div>
          <div className="px-1 py-1">
            <img className="w-full rounded-md max-h-44 object-cover" src={isValidUrl  ? imageUrl :image} alt="Card visual" />
          </div>
        </a>
      </div>
    );
  };
  export default MetaCard;