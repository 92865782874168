import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postRequestAuthenticated, postRequestAuthenticatedWithFile, postRequestUnAuthenticated } from './../../services/rest';
import { deleteChip } from './deleteChipSlice';
import {createChipComment, createChipCommentReply } from './commentChipSlice';



export const fetchProfileItems = createAsyncThunk('profileItems/fetchItems', async (userId, { rejectWithValue }) => {
    try {
      const response = await postRequestUnAuthenticated('/profile/chips/curations', {userId });
      if (response.success) {
        return response.items;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
export const fetchlimitedProfileItems = createAsyncThunk('profileItems/limited/fetchItems', async (userId, { rejectWithValue }) => {
  clearItems();

    try {
      const response = await postRequestUnAuthenticated('/limited/profile/chips/curations', {userId });
      if (response.success) {
        return response.items;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  export const createChip = createAsyncThunk('profileItems/createChip', async (newChipData, { rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticatedWithFile('/create/chip', newChipData);
      if (response.success) {
        return response.chip;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  export const updateChip = createAsyncThunk('profileItems/updateChip', async (updatedChipData, { rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticatedWithFile('/edit/chip', updatedChipData);
      if (response.success) {
        return response.chip;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  export const createCuration = createAsyncThunk('profileItems/createCuration', async (newChipData, { rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticatedWithFile('/create/curation', newChipData);
      if (response.success) {
        return response.curation;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

  export const updateCuration = createAsyncThunk('profileItems/updateCuration', async (updatedCurationData, { rejectWithValue }) => {
    try {
        const response = await postRequestAuthenticatedWithFile('/edit/curation', updatedCurationData);
        if (response.success) {
            return response.curation;
        } else {
            return rejectWithValue(response.message);
        }
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

  export const fetchGalleryItems = createAsyncThunk('profilegalleryItems/fetchItems', async (username, { rejectWithValue }) => {

    try {
      const response = await postRequestUnAuthenticated('/gallery/chips/curations', {username });
      // console.log(response);
      if (response.success) {
        return response.items;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

  export const upvoteChip = createAsyncThunk('profileItems/upvoteChip', async (chipId, { rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticated('/upvote/chip', { chip_id: chipId });
      // console.log(response);
      if (response.success) {
        return response.updatedChip; 
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  export const saveChip = createAsyncThunk('profileItems/saveChip', async ({ chipId, curationId, originId } ,{ rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticated('/save/chip', { chip_id: chipId, curation_id:curationId, origin_id:originId });
      if (response.success) {
        return response.updatedChip; 
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  export const shareChips = createAsyncThunk('chips/shareChip', async (chipId, {rejectWithValue }) => {
    try {
      const response = await postRequestUnAuthenticated('/chip/sharedby', { chip_id: chipId });
      if (response.success) {
        return response.chip;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });


export const profileItemsSlice = createSlice({
  name: 'items',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    chipstatus:'idle',
    chiperror:null,
    curationstatus:'idle',
    curationerror:null
  },
  reducers: {
   
    clearItems:(state)=>{
      state.items=[];
    },
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfileItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchProfileItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchlimitedProfileItems.pending, (state) => {
        state.status = 'loading';
    })
    .addCase(fetchlimitedProfileItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
    })
    .addCase(fetchlimitedProfileItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
    })
      .addCase(createChip.pending, (state) => {
        state.chipstatus = 'loading';
      })
      .addCase(createChip.fulfilled, (state, action) => {
        state.chipstatus = 'succeeded';
        state.items.unshift(action.payload);
      })
      .addCase(createChip.rejected, (state, action) => {
        state.chipstatus = 'failed';
        state.error = action.payload;
      })
      .addCase(createCuration.pending, (state) => {
        state.curationstatus = 'loading';
      })
      .addCase(createCuration.fulfilled, (state, action) => {
        state.curationstatus = 'succeeded';
        state.items.unshift(action.payload);
      })
      .addCase(createCuration.rejected, (state, action) => {
        state.curationstatus = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCuration.pending, (state) => {
        state.curationstatus = 'loading';
      })
      .addCase(updateCuration.fulfilled, (state, action) => {
        state.curationstatus = 'succeeded';
        const index = state.items.findIndex(curation => curation._id === action.payload._id && curation.type === "curation");
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateCuration.rejected, (state, action) => {
        state.curationstatus = 'failed';
        state.curationerror = action.payload || action.error.message;
      })
      .addCase(updateChip.pending, (state, action) => {
        state.chipstatus = 'loading';
      })
      .addCase(updateChip.rejected, (state, action) => {
        state.curationstatus = 'failed';
      })
      .addCase(updateChip.fulfilled, (state, action) => {
        const index = state.items.findIndex(chip => chip._id === action.payload._id && chip.type === "chip");
        if (index !== -1) {
          state.items[index] = action.payload;
        }
        state.chipstatus = 'succeeded';
      })
      .addCase(fetchGalleryItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGalleryItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchGalleryItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createChipComment.fulfilled, (state, action) => {
        const updatedComment = action.payload;
        const index = state.items.findIndex(item => item._id === updatedComment.chipId);
        if (index !== -1) {
          state.items[index].comments+=1;
        }
    })
    .addCase(createChipCommentReply.fulfilled, (state, action) => {
        const updatedReply = action.payload;
        const index = state.items.findIndex(item => item._id === updatedReply.parentCommentId.chipId);
        if (index !== -1) {
            state.items[index].comments +=1; 
        }
    })
      .addCase(upvoteChip.fulfilled, (state, action) => {
        const updatedChip = action.payload;
        const index = state.items.findIndex(item => item._id === updatedChip._id);
        if (index !== -1) {
            state.items[index].upvotes = updatedChip.upvotes; 
        }
    })
    .addCase(shareChips.fulfilled, (state, action) => {
      const chip = action.payload;
      const index = state.items.findIndex(item => item._id === chip._id);
      if (index !== -1) {
        state.items[index].shared_by = chip.shared_by; 
      }
    })
    .addCase(saveChip.fulfilled, (state, action) => {
        const updatedChip = action.payload;
        const index = state.items.findIndex(item => item._id === updatedChip._id);
        if (index !== -1) {
            state.items[index].saved_by = updatedChip.saved_by; 
        }
    })
    .addCase(deleteChip.fulfilled, (state, action) => {
        const chipId = action.payload;
        const index = state.items.findIndex(item => item._id === chipId);
        if (index !== -1) {
          state.items.splice(index, 1);
        }
    })
  },
});

export const {clearItems} = profileItemsSlice.actions;

export default profileItemsSlice.reducer;
export const selectProfileItemsStatus = (state) => state.profileItems.status;
export const selectProfileItemsError = (state) => state.profileItems.error;

