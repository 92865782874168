import {React,useEffect} from 'react'
import ArrowBack from "../../../assets/icons/arrow_back.svg";
import ProfileIcon from "../../../assets/icons/profile.svg";
import { useSelector, useDispatch } from 'react-redux';
import { fetchmySubscriptions } from './../../../redux/slices/homeSlice';
import useModal from './../../hooks/ModalHook';
import { useNavigate } from 'react-router-dom';

const ManageSubscriptions = () => {
  const {subscriptions} = useSelector((state) => state.homeItems);
  const dispatch = useDispatch();
  const { handleOpenModal} = useModal();
  const navigate = useNavigate();


  useEffect(() => {
      dispatch(fetchmySubscriptions())
  }, [dispatch]);

  const handleNavigateHome=(value)=>{
    navigate("/");
  }
  const handleNavigateSubscription=(value)=>{
      navigate(-1); 
  }

  const handleSubscription=(username)=>{
    handleOpenModal('modalUnsubscriptionOpen', username);

  }

  return (
    <div className="flex flex-col pr-8 w-full">
            <div className="flex flex-row items-center cursor-pointer -ml-2" onClick={handleNavigateHome}>
              <img src={ArrowBack} alt="arrow-back" className="text-primary font-normal text-lg font-inter"/>
              <p className="ml-2 text-lightText text-xs font-light font-inter">Back to Explore</p>
            </div>
            <div className="flex flex-row mt-4 justify-between items-center">
              <p className="xs:text-3xl text-xl text-white font-normal font-familjen-grotesk">My Subscriptions</p>
              <div className="bg-primary text-buttonText px-4 text-sm font-normal py-2 items-center rounded-full cursor-pointer"
              onClick={handleNavigateSubscription} 
              >
                 Done
              </div>
            </div>
        <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-4 mt-8">
          {subscriptions.map((subscription, index) => (
            <div key={index} className="flex flex-col items-center">
              <img src={subscription.logo?subscription.logo:ProfileIcon} alt={subscription.name} className="rounded-full w-16 h-16 object-cover" />
              <p className="text-white font-normal font-inter text-sm mt-2">{subscription.name}</p>
              <button className={`px-4 mt-2  py-2.5 bg-buttonBackground text-primary text-xs rounded-lg`} onClick={()=>handleSubscription(subscription.username)}>Unsubscribe</button>
            </div>
          ))}
        </div>
    </div>
  )
}

export default ManageSubscriptions
