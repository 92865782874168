import React from 'react'

const EmptyProfileCard = ({handleClick}) => {
  return (
    <div className="h-full w-full  lg:ml-0 md:ml-8 bg-emptyProfileBackground border border-borderColor p-4 sm:p-8 rounded-lg"
    >
        <div className="flex flex-col">
        <p className="text-white text-medium md:text-lg font-medium font-inter tracking-tight">Visuals are more effective than words</p>
        <p className="text-textColor text-xs mt-1 font-normal font-inter tracking-tight">Show them what your profile is all about with relevant images</p>
        <img src="https://chips-social.s3.ap-south-1.amazonaws.com/profileEmptyCard.png" alt= "profile" className="rounded-lg h-64 md:h-76 lg:h-64 mt-4 bg-[#FEE07C] w-full object-contain"/>
        <div className="pt-2"/>
            <button  onClick={handleClick} className="mt-3 border w-1/2 sm:2/5 lg:w-1/3 border-primary py-3 rounded-full text-primary font-normal text-sm font-inter">
                        Add Images
            </button>
        </div>
        
        </div>
      
  )
}

export default EmptyProfileCard
