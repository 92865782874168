import { combineReducers } from '@reduxjs/toolkit';
import authReducer, { logOut } from '../slices/authSlice';
import networkReducer from '../../components/context/Network/networkReducer';
import profileReducer from "../slices/profileSlice";
import myDataReducer from "../slices/myDataSlice";
import curationReducer from "../slices/curationSlice";
import chipReducer from "../slices/chipSlice";
import editChipReducer from "../slices/editChipSlice";
import profileItemsReducer from './../slices/profileItemsSlice';
import imageCardsReducer from './../slices/imageCardsSlice';
import modalReducer from './../slices/modalSlice';
import deleteChipReducer from './../slices/deleteChipSlice';
import deleteCurationReducer from './../slices/deleteCurationSlice';
import galleryReducer from './../slices/gallerySlice';
import curationPageReducer from './../slices/curationPageSlice';
import savedReducer from './../slices/savedSlice';
import chipItemReducer from './../slices/chipItemSlice';
import curationEngagementReducer from './../slices/curationEngagementSlice';
import chipEngagementReducer from './../slices/chipEngagementSlice';
import profileEngagementReducer from './../slices/profileEngagementSlice';
import searchItemsReducer from './../slices/searchSlice';
import  homeItemsReducer  from './../slices/homeSlice';
import categoryCurationReducer from './../slices/categoryCurations';
import  homeCardReducer from './../slices/homeCardSlice';
import  uiReducer  from './../slices/uiSlice';
import commentReducer from './../slices/commentChipSlice';

const appReducer = combineReducers({
  auth: authReducer,
  network: networkReducer,
  profileData: profileReducer,
  myData: myDataReducer,
  curation: curationReducer,
  chip: chipReducer,
  editChip: editChipReducer,
  profileItems: profileItemsReducer,
  imageCards: imageCardsReducer,
  modals: modalReducer,
  chipDeletion: deleteChipReducer,
  curationDeletion: deleteCurationReducer,
  galleryData: galleryReducer,
  curationPage: curationPageReducer,
  saved: savedReducer,
  chipItem: chipItemReducer,
  curationEngagement:curationEngagementReducer,
  chipEngagement:chipEngagementReducer,
  profileEngagement:profileEngagementReducer,
  searchItems:searchItemsReducer,
  homeItems:homeItemsReducer,
  categoryCurations:categoryCurationReducer,
  homeCard:homeCardReducer,
  ui: uiReducer,
  commentChip:commentReducer,

});

const rootReducer = (state, action) => {
  if (action.type === logOut.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
