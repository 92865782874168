import React, { useState, useRef, useEffect } from 'react';
import {FaPencilAlt } from 'react-icons/fa';
import AddIcon from "../../assets/icons/add_icon.svg";
import ShareIcon from "../../assets/icons/shareIcon.svg";
import ProfileIcon from "../../assets/icons/profile.svg";
import ChipIcon from "../../assets/icons/chip_icon.svg";
import CurationIcon from "../../assets/icons/curation_icon.svg";
import ProfileView from './Widgets/ProfileView';
import ProfileForm from './FormProfile/ProfileForm';
import ProfileCarousel from './Widgets/ProfileCarousel';
import UnsplashModal from './../Modals/UnsplashModal';
import { useDispatch, useSelector } from 'react-redux';
import useModal from './../hooks/ModalHook';
import { useParams,  useNavigate } from 'react-router-dom';
import { fetchProfile, selectProfileStatus } from './../../redux/slices/profileSlice';
import ProfileSkeleton from './../skeleton/profileSkeleton';
import { setActiveTab, toggleSubscription } from '../../redux/slices/profileSlice';
import { Outlet } from 'react-router-dom';
import EmptyProfileCard from './Widgets/EmptyProfileCard';
import { domainUrl } from './../../utils/globals';
import { setProfileEngagement } from './../../redux/slices/profileEngagementSlice';
import Others from "../../assets/icons/Subtract.svg";
import { postRequestUnAuthenticated } from './../../services/rest';



const Profile = () => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isUnsplashModalOpen, setIsUnsplashModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDomainExist, setIsDomainExist] = useState(true);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const myData = useSelector((state) => state.myData);
  const { handleOpenModal} = useModal();
  const navigate = useNavigate();
  const {username} = useParams();
  const profileStatus = useSelector(selectProfileStatus);
  const profileData = useSelector((state) => state.profileData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);


  const isOwner = myData?.username ===username;

  const { items } = useSelector((state) => state.profileItems);

  
  useEffect(() => {
    const checkSubdomainExists = async (subdomain) => {
      try {
        const response = await postRequestUnAuthenticated(`/username/exist`,{username:subdomain});
        setIsLoading(false);
        if(response.success){
          dispatch(fetchProfile(username));
        }
        else{
          setIsDomainExist(false);
        }
      } catch (error) {
        console.error('Error checking subdomain:', error);
        setIsLoading(false);
        setIsDomainExist(false);
      }
    };
    checkSubdomainExists(username);
  }, [username, dispatch]); 

  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };


  const openUnsplashModal = () => setIsUnsplashModalOpen(true);
  const closeUnsplashModal = () => setIsUnsplashModalOpen(false);

  const openBottomSheet = () => setIsBottomSheetOpen(true);
  const closeBottomSheet = () => setIsBottomSheetOpen(false);

  const handleCurationOpenModal=()=>{
    setIsDropdownOpen(false);
    handleOpenModal('modalCurationOpen');
  }
  const handleChipOpen = () => {
    setIsDropdownOpen(false);
    handleOpenModal('modalChipOpen');
  };

  const openShareModal = (link) => {
    handleOpenModal('modalShareProfileOpen', link);
    dispatch(setProfileEngagement(profileData._id));
  };


  const handleSubscription= ()=>{
    if(isLoggedIn){
      dispatch(toggleSubscription(profileData))
    }
    else{
      handleOpenModal("modalLoginOpen");
    }
  }

  const handleEditCards=()=>{
    dispatch(setActiveTab("displayCards"));
    setIsBottomSheetOpen(true);
  }

  if(isLoading){
    return <ProfileSkeleton/>
  }

  if(!isDomainExist){
    navigate(`/profile/${username}/404`);
    return;
  }
 
  const hasImages = profileData.imageCards.length!==0;
  const isSubscribed = profileData.subscribers?.includes(myData._id);
  
  return (
    <div className={`w-full pr-5 xs:pr-8 sm:pr-16 h-full `} onClick={handleClickOutside}>
      {profileStatus==="loading"?(<ProfileSkeleton/>):(
        <>
      {isOwner && <button
        className={`xs:bg-dark p-2 flex flex-row absolute right-0 xs:right-16 ${isOwner && !hasImages?"xl:right-[14%] xxl:right-[13%]":"xl:right-[10%] xxl:right-[9%]"}  items-center -mt-8 rounded-full `}
        onClick={openBottomSheet}>
        <FaPencilAlt className="w-3 h-3 text-textFieldColor mb-0.5" />
        <p className="text-textFieldColor ml-2 text-xs font-normal font-inter">Edit Profile</p>
      </button>}
      <div className={`mt-12 py-1 ${isOwner && !hasImages?"xl:w-4/5 xl:mx-auto":"xl:w-[90%] xl:mx-auto"}`}>
      <div className={`${isOwner || hasImages ? 'md:bg-profileBackground' : ''} bg-primaryBackground rounded-lg md:px-7 px-0  py-4 sm:py-8 w-full`}>
           <div className={`flex flex-col rounded-md md:flex-row ${isOwner || hasImages ? 'items-center md:justify-between justify-center' : 'items-center justify-center'} `}>
            <div className={`md:w-2/5 w-full flex flex-col ${isOwner || hasImages ? "md:justify-start md:items-start justify-center items-center":"justify-center items-center"}  mb-4`}>
             {profileData.logo ? <img
                src={profileData.logo}
                alt="Profile"
                className="rounded-full w-28 h-28 border border-white object-cover"
                style={{ borderWidth: "3px" }}
              />:<img
              src={ProfileIcon}
              alt="Profile"
              className="rounded-full w-28 h-28  bg-buttonBackground p-6 object-cover"
            />}
              <div className={`mt-1 md:ml-1  ${isOwner || hasImages ? 'profile-text' : 'text-center'}`}>
                <p className={`text-2xl  text-white font-normal font-familjen-grotesk`}>{profileData.name}</p>
                <p className="mt-1 text-xs  font-normal text-viewAll font-inter">{profileData.username}.{domainUrl}</p>
                <p className="mt-1 text-xs  font-normal text-viewAll font-inter">{profileData.subscribers.length} Subscribers</p>
                <p className="mt-2 text-sm font-light text-textColor whitespace-pre-wrap overflow-hidden overflow-wrap break-word">
                 {profileData.description}
                </p>
                {(profileData.location || profileData.contact) && <p className="mt-2 text-xs text-viewAll">{profileData.location}{profileData.contact && profileData.location?" | ":""}{profileData.contact}</p>}
                <div className={`flex space-x-4  ${isOwner || hasImages ? 'md:justify-start justify-center' : 'justify-center'}`}>
                  {profileData.customText && profileData.customUrl &&  <a href={profileData.customUrl} 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-5 mt-4  py-2 bg-primary text-buttonText text-sm rounded-lg">{profileData.customText}</a>}
                  {!isOwner &&  <button className={`px-4 mt-4  py-2 ${isSubscribed?"bg-dark":"bg-buttonBackground"} text-primary text-sm rounded-lg`} onClick={handleSubscription}>{isSubscribed?"Subscribed":"Subscribe" }</button>}
                
                </div>
                <div className={`flex ${isOwner || hasImages?"md:justify-start justify-center":"justify-center"}`}>
                <div className="w-64 my-3  border border-borderColor " style={{ height: '0.1px' }}></div>
                </div>
                <div className={`flex space-x-4 ${isOwner || hasImages?'justify-center md:justify-start':'justify-center'}`}>
                      <div 
                      className="bg-primary rounded-full cursor-pointer "
                      onClick={()=>openShareModal(`https://`+profileData.username+`.${domainUrl}`)}
                      >
                      <img src={ShareIcon} alt="save" />
                    </div>
                    {profileData.links.map((link, index) => (
                       link.value && (
                       (
                            <a 
                              href={link.url + link.value} 
                              className="text-white cursor-pointer"
                              key={index}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={link.image} alt={link.value} className="w-8 h-8"/>
                            </a>
                         )
                      )
                      ))}
                     {profileData.otherLink && 
                      <a 
                      href={profileData.otherLink} 
                      className="text-white cursor-pointer" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <img src={Others} alt="other-link" className="w-8 h-8"/>
                    </a>
                      }
                </div>
              </div>
            </div>
            
            {hasImages ? (<div className={`flex md:ml-4 md:mt-0 mt-4 md:mb-0 justify-center ${(!profileData.description || profileData.description.length<=40)?"md:w-3/5 xl:w-2/5":"md:w-3/5 xl:w-1/2"} h-full w-full md:justify-end mb-2`}>
              <ProfileCarousel images={profileData.imageCards} />
            </div>
             ) :
             isOwner ? (   <div className={`flex md:ml-4 md:mt-0 mt-4 md:mb-0 justify-center ${(!profileData.description || profileData.description.length<=40)?"md:w-3/5 xl:w-3/5":"md:w-3/5 lg:w-1/2"} h-full w-full md:justify-end mb-2`}>
             <EmptyProfileCard handleClick={handleEditCards} />
             </div>
              ) : null}
          </div>
        </div>
        <div className={`flex justify-between items-center ${hasImages ? 'mt-2 xs:mt-6' : 'mt-1'} -ml-2`}>
          {isOwner && items.length>0 && <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
            <div className="rounded-md flex justify-center items-center">
              <img src={AddIcon} alt="Add" className="w-13 h-12 mt-3" />
            </div>
            <p className="text-primary font-normal text-xs -ml-1">Create new</p>
          </div>}
          {isDropdownOpen && (
            <div
              ref={dropdownRef}
              className="absolute mt-28 ml-3 w-28 rounded-md shadow-lg border border-dividerLine bg-chipBackground ring-1 ring-black ring-opacity-5 z-50"
            >
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              
                <div className="flex flex-row px-3 items-center" onClick={handleChipOpen}>
                        <img src={ChipIcon} alt="edit" className="w-4 h-4"/>
                        <p
                  className="block px-2 py-2 text-sm text-textFieldColor cursor-pointer"
                  role="menuitem"
                  
                >
                  Chip
                </p>
                  </div>
                  <div className="flex flex-row px-3 items-center" onClick={handleCurationOpenModal}>
                        <img src={CurationIcon} alt="edit" className="w-4 h-4"/>
                        <p
                        className="block px-2 py-2 text-sm  text-textFieldColor cursor-pointer"
                        role="menuitem"
                      >
                        Curation
                      </p>
                  </div>
              </div>
            </div>
          )} 
        </div>
        <ProfileView gallery={false} owner={isOwner}/>
      </div>
      <ProfileForm
        isOpen={isBottomSheetOpen}
        onClose={closeBottomSheet}
        onUnsplashClick={openUnsplashModal}
      />
      <UnsplashModal
        isOpen={isUnsplashModalOpen}
        onClose={closeUnsplashModal}
      />
      </>
    )}
    <Outlet />
    </div>
  );
};

export default Profile;
