import {React, useState} from 'react';
import SubscriptionsList from './SubscriptionsList';
import ProfileIcon from "../../../assets/images/profile.svg";
import { clearSubscriptionItems,fetchHomeSubscribeItems } from './../../../redux/slices/homeSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ProfileItemsSkeleton from './../../skeleton/profileItemsSkeleton';
import { useNavigate } from 'react-router-dom';

const MySubscriptions = ({ profiles }) => {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const dispatch = useDispatch();
  const {items,itemsStatus} = useSelector((state) => state.homeItems);
  const navigate = useNavigate();


  const handleProfileClick = (profile) => {
    dispatch(clearSubscriptionItems());
    if (window.innerWidth < 470) {
      navigate(`/profile/${profile.username}`);
    } 
    else{
      if(selectedProfile===profile){
        setSelectedProfile(null);
      }
      else{
        setSelectedProfile(profile);
        dispatch(fetchHomeSubscribeItems(profile._id))
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            // console.log("fetchCategoryCurations Success:", response.payload);
          } else {
            // console.log("fetchCategoryCurations Error:", response.error);
          }
        });
      }
    }
  };
  const handleHide = () => {
    setSelectedProfile(null);
  };


  return (
    <div className="w-full mt-2">
      <div className="flex xs:space-x-6 space-x-4 overflow-x-auto no-scrollbar -ml-2 ">
        {profiles.map((profile, index) => (
          <div key={index} className={`flex flex-col items-center flex-shrink-0 ${
            selectedProfile === profile ? 'bg-dark border border-primary' : ''
          } p-2 rounded-lg`}>
           {profile.logo?(<img
              src={profile.logo}
              alt={profile.name}
              className="xs:h-24 xs:w-24 h-20 w-20px rounded-full object-cover  cursor-pointer"
              onClick={() => handleProfileClick(profile)}
            />):(<img
              src={ProfileIcon}
              alt={profile.name}
              className="xs:h-24 xs:w-24 h-20 w-20px rounded-full object-cover  cursor-pointer"
              onClick={() => handleProfileClick(profile)}
            />) }
            <p className="text-white text-sm mt-2 font-inter font-normal text-center">{profile.name}</p>
          </div>
        ))}
      </div>
       {selectedProfile !== null ? (
        itemsStatus==="loading"?<ProfileItemsSkeleton/>:<SubscriptionsList profile={selectedProfile} items={items} onHide={handleHide} />
      ) : (
        null
      )} 
    </div>
  );
};

export default MySubscriptions;
