import {React,useEffect} from 'react'
import Hashtag from '../../../assets/images/hashtag.svg';
import Chip1 from '../../../assets/icons/chip_1.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrendingChip } from './../../../redux/slices/homeCardSlice';
import { useNavigate } from 'react-router-dom';

const HashtagBanner = () => {

  const dispatch = useDispatch();

  const {chips,chipStatus} = useSelector((state)=>state.homeCard);
  const navigate = useNavigate();

  useEffect(() => {
      dispatch(fetchTrendingChip())
  }, [dispatch]);



  const handleChipOpen=()=>{
    navigate(`/user/${chips[0].user.username}/chip/${chips[0]._id}`)
  }


  return (
    <div className="bg-dark px-3 sm:px-5 pt-4 pb-5 rounded-lg flex flex-row h-64">
    <div className="mr-2 xs:mr-4 sm:mr-12 xxl:mr-16">
      <img src={Hashtag} alt="Logo" className="h-12 mt-4" />
      <h1 className="text-white xs:text-xl text-lg sm:text-2xl font-medium font-familjen-grotesk mt-24 w-24 xs:w-36" >The #1 Chip</h1>
      <p className="text-textColor text-xs font-light font-familjen-grotesk mt-1 xs:mt-2 w-24 xs:w-36">By people's choice</p>
    </div>
    <div className="relative xs:w-48 w-44 h-48 xs:h-52 pt-2">
  <img className="xs:w-48 w-44 h-48 xs:h-52 rounded-lg" src={Chip1} alt="#1 Chip" />
  <div className="mt-1 absolute inset-0 flex items-center justify-center cursor-pointer" onClick={handleChipOpen}>
    <div className="px-3 py-2 bg-white rounded-full text-sm font-normal font-inter text-buttonText ">
      Reveal Chip
    </div>
  </div>
</div>

    
</div>
  )
}

export default HashtagBanner
