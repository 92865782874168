import React from 'react'
import useModal from './../../hooks/ModalHook';

import ChipIcon from "../../../assets/icons/chipEmptyIcon.svg";
import CurationIcon from "../../../assets/icons/curationEmptyIcon.svg";
const EmptyItemsCard = () => {

    const { handleOpenModal} = useModal();
    
    const handleCurationOpenModal=()=>{
        handleOpenModal('modalCurationOpen');
      }
      const handleChipOpen = () => {
        handleOpenModal('modalChipOpen');
      };
    
  return (
    <div className="flex sm:flex-row flex-col sm:justify-around mt-8 ">
        <div  className="bg-chipBackground p-4 xs:p-8 xl:w-2/5 lg:w-2/5 md:w-[47%] sm:w-[45%] rounded-lg">
            <div className="flex flex-col space-y-2">
                <img src={ChipIcon} alt="chip-icon" className="w-10 h-10"/>
                <p className="text-white font-normal text-base">Convey better with chips</p>
                <img src="https://chips-social.s3.ap-south-1.amazonaws.com/profileChipCard.png" alt= "chip-profile" className="lg:h-52 md:h-48  h-44 bg-emptyChip rounded-lg w-full object-contain"/>
                <ul className="list-disc list-inside space-y-1 pt-2 text-primaryGrey text-sm font-light font-inter">
                Organise and share links, media, and more within customisable Chips. Each Chip serves as a building block for your digital space, making it easy to curate and present content in a visually engaging way.
                
                </ul>
                <div className="pt-2"/>
                    <button className="mt-4 border border-primary py-3 rounded-full text-primary font-normal text-sm font-inter"
                    onClick={handleChipOpen}
                    >
                        Create a Chip
                    </button>
            </div>
        </div>
        <div  className="bg-chipBackground p-4 xs:p-8 mt-6 sm:mt-0  xl:w-2/5 lg:w-2/5 md:w-[47%] sm:w-[45%] rounded-lg">
            <div className="flex flex-col space-y-2">
                <img src={CurationIcon} alt="curation-icon" className="w-10 h-10"/>
                <p className="text-white font-normal text-base">Set context with Curations</p>
                <img src="https://chips-social.s3.ap-south-1.amazonaws.com/profileCurationCard.png" alt= "chip-profile" className="lg:h-52 md:h-48  h-44 bg-emptyCuration rounded-lg w-full object-contain"/>
                <ul className="list-disc list-inside space-y-1 pt-2 text-primaryGrey text-sm font-light font-inter">
                Curations are collections of Chips that bring together related content. Create and organise these collections to showcase your interests, projects, or anything that inspires you, all in one cohesive place.
                </ul>
                <div className="pt-2"/>
                    <button className="mt-4 border border-primary py-3 rounded-full text-primary font-normal text-sm font-inter"
                    onClick={handleCurationOpenModal}
                    >
                        Build a Curation
                    </button>

            </div>
        </div>

 
      
    </div>
  )
}

export default EmptyItemsCard
