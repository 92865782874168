// src/components/RightComponent.js
import {React,useEffect} from 'react';
import Editors from '../../../assets/images/editors.svg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchEditorsCurations } from './../../../redux/slices/homeCardSlice';
import BannerCurationSkeleton from './../../skeleton/banners/bannerCurationSkeleton';


const RightComponent = () => {

  const dispatch = useDispatch();

  const {curations, curationStatus} = useSelector((state)=>state.homeCard);

  const navigate = useNavigate();

  useEffect(() => {
      dispatch(fetchEditorsCurations());
  }, [ dispatch]);


  const handleCurationOpen=(id)=>{
    navigate(`/curation/${id}`)
  }


  return (
    <div className="bg-dark px-3 xs:px-5 pt-2 pb-4 rounded-lg flex flex-row h-64">
      <div className="sm:mr-10 xs:mr-4 xxl:mr-14 mr-2">
        <img src={Editors} alt="Logo" className="h-12 mt-5" />
        <h2 className="text-white xs:text-xl text-lg sm:text-2xl font-medium font-familjen-grotesk mt-10 xs:mt-14 w-24 xs:w-32">Editor's 3 picks</h2>
        <p className="text-textColor text-xs font-light font-familjen-grotesk mt-1 sm:mt-2 w-24 xs:w-36">Curation that stole our hearts</p>
      </div>
    <div className="w-44 xs:w-48 sm:w-72 mr-2">
      {curationStatus==="loading"?<BannerCurationSkeleton/>: curations.map((curation, index) => (
        <div key={index} className={`flex items-center justify-between py-2.5 xs:py-1.5 sm:py-2.5 ${index===curations.length-1?"":'border-b border-borderColor' }`}
        onClick={()=>handleCurationOpen(curation._id)}
        >
          <div className="flex items-start cursor-pointer">
            <img src={curation.image} alt={curation.name} className="h-12 xs:h-14 rounded-xl object-cover flex-shrink-0 xs:w-[80px] w-16"  />
              <div className=" ml-2 xs:ml-4">
                <p className="text-white font-normal font-inter text-xs xs:text-sm overflow-hidden overflow-ellipsis" 
                style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', lineHeight: '1.5' }}>{curation.name}</p>
                <p className=" text-lightText font-light xs:font-normal mt-1  xs:mt-2 font-inter text-xs">{curation.user.name}</p>
              </div>
          </div>
        </div>
      ))}
      </div>
  </div>
  );
};

export default RightComponent;
